<template>
  <div class="page-wrapper">
    <!-- Calcultaor -->
    <section v-if="!result" class="split-screen-layout">
      <!-- Visual IMG -->
      <div class="layout-box visual-layout">
        <h1 class="seo">Syndicat Québécois de la Construction</h1>
        <router-link class="logo" :to="{ name: 'home' }"
          ><img
            src="@/assets/img/syndicat-quebecois-de-la-construction-logo.svg"
            alt="Syndicat Québécois de la Construction"
        /></router-link>

        <img
          v-if="currentStep === 1"
          class="background-visual"
          src="@/assets/img/step-1.jpg"
          alt="Employés : Syndicat Québécois de la Construction"
        />
        <img
          v-if="currentStep === 2"
          class="background-visual"
          src="@/assets/img/step-2.jpg"
          alt="Employés : Syndicat Québécois de la Construction"
        />
        <img
          v-if="currentStep === 3"
          class="background-visual"
          src="@/assets/img/step-3.jpg"
          alt="Employés : Syndicat Québécois de la Construction"
        />
      </div>

      <!-- Form section -->
      <div class="layout-box content-layout">
        <!-- Top pagination -->
        <aside class="top-bar">
          <h2 class="small-title">Comparez les cotisations</h2>
          <span v-if="currentStep" class="small-title pagination"
            >{{ currentStep }}/{{ steps }}</span
          >
        </aside>

        <!-- Form -->
        <div class="form-container">
          <!-- Form Metier -->
          <form
            v-if="currentStep === 1"
            class="form form-full-height"
            action=""
            @submit="selectMetier"
          >
            <h3 class="big-title">
              Quel est votre <br />
              Métier / Occupation?
            </h3>
            <select
              id="metier"
              v-model="metier"
              name="metier"
              :style="'background-image: url(' + arrowDown + ');'"
              required
            >
              <option value="" selected>Métier / Occupation</option>
              <option
                v-for="(metierName, slug) in metiers"
                :key="slug"
                :value="slug"
              >
                {{ metierName }}
              </option>
            </select>
            <p class="small-text form-informations">
              * Si votre métier ou occupation n'est pas présent dans la liste,
              <br />
              <a
                href="https://www.sqc.ca/nous-joindre/"
                target="_blank"
                title="Communiquer avec un représentant SQC"
                >vous pouvez communiquer avec un représentant SQC</a
              >
              afin d'obtenir des renseignements.
            </p>
            <button type="submit" class="site-btn">Suivant</button>
          </form>

          <!-- Form Statuts -->
          <form
            v-if="currentStep === 2 && metier && nbLoop !== 'occupation'"
            class="form form-full-height"
            :class="'has-' + nbLoop"
            action=""
            @submit="selectStatut"
          >
            <h3 class="big-title">Quel est votre Statut?</h3>

            <div
              v-for="(statutName, slug) in statuts"
              :key="slug"
              class="checkbox"
              :class="slug"
              @click.prevent="checkStatut(slug)"
            >
              <input
                :id="slug"
                type="checkbox"
                name="statut"
                :value="slug"
                :checked="statut === slug"
              />
              <label :for="slug">{{ statutName }}</label>
            </div>

            <p v-if="showError" class="form-error">{{ showError }}</p>
            <button type="submit" class="site-btn">Suivant</button>
          </form>
          <form
            v-else-if="currentStep === 2 && metier && nbLoop === 'occupation'"
            class="form form-full-height"
            action=""
            @submit="selectStatut"
          >
            <h3 class="big-title">Quel est votre Statut?</h3>

            <div
              class="checkbox"
              :class="'occupation'"
              @click.prevent="checkStatut('occupation')"
            >
              <input
                :id="'occupation'"
                type="checkbox"
                name="statut"
                :value="'occupation'"
                :checked="statut === 'occupation'"
              />
              <label :for="'occupation'">Occupation</label>
            </div>

            <p v-if="showError" class="form-error">{{ showError }}</p>
            <button type="submit" class="site-btn">Suivant</button>
          </form>

          <!-- Form Syndicat -->
          <form
            v-if="currentStep === 3"
            class="form form-full-height"
            action=""
            @submit="selectSyndicat"
          >
            <h3 class="big-title">Quel est votre Syndicat actuel?</h3>

            <div
              v-for="(syndicatName, slug) in syndicats"
              :key="slug"
              class="checkbox"
              @click.prevent="checkSyndicat(slug)"
            >
              <input
                :id="slug"
                type="checkbox"
                name="syndicat"
                :value="slug"
                :checked="syndicat === slug"
              />
              <label :for="slug">{{ syndicatName }}</label>
            </div>

            <p v-if="showError" class="form-error">{{ showError }}</p>
            <button type="submit" class="site-btn">Suivant</button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'

import {
  metiers,
  metiersStatutLoop,
  statuts,
  syndicats,
  refs,
} from '@/misc/static-database'

import arrowDown from '@/assets/img/arrow-down.svg'

export default {
  head() {
    return {
      title: {
        inner: 'Calculez votre cotisation avec le SQC',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `${this.appTitle}` },
        {
          name: 'description',
          content: `${this.appTitle} Si votre métier ou occupation n'est pas présent dans la liste, vous pouvez communiquer avec un représentant SQC afin d'obtenir des renseignements.`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: `${this.appTitle}` },
        {
          n: 'twitter:description',
          c: `${this.appTitle} Si votre métier ou occupation n'est pas présent dans la liste, vous pouvez communiquer avec un représentant SQC afin d'obtenir des renseignements.`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `${this.appTitle}` },
        { property: 'og:site_name', content: `${this.appTitle}` },
        {
          property: 'og:description',
          content: `${this.appTitle} Si votre métier ou occupation n'est pas présent dans la liste, vous pouvez communiquer avec un représentant SQC afin d'obtenir des renseignements.`,
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/syndicat-qc-construction.appspot.com/o/employes-syndicat-quebecois-de-la-construction.jpg?alt=media&token=21ea75ec-bdee-47c9-a676-e9e0654a2e7e',
        },
      ],
    }
  },
  data() {
    return {
      arrowDown,
      steps: 3,
      metiers,
      statuts,
      syndicats,
      metier: '',
      metiersStatutLoop,
      statut: '',
      syndicat: '',
      result: null,
      resultUrl: null,
      showError: '',
    }
  },
  watch: {
    $route: {
      async handler($route) {
        if (!isNil($route) && $route.params) {
          const { params } = $route
          if (params.metier) {
            console.log({ metier: params.metier })
            this.metier = params.metier
          } else {
            this.metier = ''
            this.result = null
          }

          if (params.statut) {
            console.log({ statut: params.statut })
            this.statut = params.statut
          } else {
            this.statut = ''
            this.result = null
          }

          if (params.syndicat) {
            console.log({ syndicat: params.syndicat })
            this.syndicat = params.syndicat
          } else {
            this.syndicat = ''
            this.result = null
          }

          console.log(
            'Imported',
            { metiers, statuts, syndicats, refs, metiersStatutLoop },
            metiersStatutLoop[this.metier]
          )
        }
      },
      immediate: true,
    },
  },
  computed: {
    currentStep() {
      return Object.keys(this.$route.params).length + 1
    },
    nbLoop() {
      return this.metiersStatutLoop[this.metier]
    },
    ...mapState('app', ['appTitle', 'appDomain']),
  },
  methods: {
    selectMetier(e) {
      e.preventDefault()
      console.log('metier', this.metier)
      this.$router.push({
        name: 'calculation-statut',
        params: { metier: this.metier },
      })
      this.showError = ''
    },
    selectStatut(e) {
      e.preventDefault()

      if (!this.statut) {
        this.showError = 'Vous devez sélectionner un statut'
        return
      }

      console.log('metier', this.metier)
      console.log('statut', this.statut)

      this.$router.push({
        name: 'calculation-syndicat',
        params: { metier: this.metier, statut: this.statut },
      })
      this.showError = ''
    },
    selectSyndicat(e) {
      e.preventDefault()

      if (!this.syndicat) {
        this.showError = 'Vous devez sélectionner un syndicat'
        return
      }

      console.log('metier', this.metiers[this.metier])
      console.log('statut', this.statuts[this.statut])
      console.log('syndicat', this.syndicats[this.syndicat])

      this.$router.push({
        name: 'result',
        params: {
          metier: this.metier,
          statut: this.statut,
          syndicat: this.syndicat,
        },
      })
      this.showError = ''
    },
    checkStatut(statut) {
      this.statut = statut
    },
    checkSyndicat(syndicat) {
      this.syndicat = syndicat
    },
    ...mapActions('app', ['doAlert']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

// Calculator Page
.split-screen-layout {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;

  .layout-box {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 600px) {
      width: 50%;
      min-height: 100vh;
    }
  }

  .visual-layout {
    text-align: center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($dark, 0.6);
      z-index: 1;
    }

    .logo {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      z-index: 5;

      width: 100%;
      max-width: 165px;

      @media (min-width: 600px) {
        top: 25px;
        max-width: 275px;
      }
    }

    .background-visual {
      display: block;
      height: 30vh;
      -o-object-fit: cover;
      object-fit: cover;

      @media (min-width: 600px) {
        height: 100vh;
      }

      @media (max-width: 600px) {
        object-position: 50% 20%;
      }
    }
  }

  .content-layout {
    padding: 20px;

    @media (min-width: 600px) {
      padding: 30px;
    }

    @media (min-width: 769px) {
      padding: 65px;
    }

    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      .pagination {
        letter-spacing: 0.35rem;
        font-size: 24px;
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-top: 8vw;
      text-align: center;

      .form {
        max-width: 600px;

        select {
          max-width: 500px;
        }

        .form-informations {
          padding-top: 15px;
        }
      }

      .form.has-1 {
        .checkbox.apprenti-2,
        .checkbox.apprenti-3,
        .checkbox.apprenti-4,
        .checkbox.occupation {
          display: none;
        }
      }

      .form.has-2 {
        .checkbox.apprenti-3,
        .checkbox.apprenti-4,
        .checkbox.occupation {
          display: none;
        }
      }

      .form.has-3 {
        .checkbox.apprenti-4,
        .checkbox.occupation {
          display: none;
        }
      }

      .form.has-4 {
        .checkbox.occupation {
          display: none;
        }
      }
    }
  }
}

// Result page
.hero {
  position: relative;
  min-height: 45vw;

  .hero-content {
    position: relative;
    z-index: 2;
    padding: 20px 0;
    color: #fff;
    text-align: center;

    @media (min-width: 600px) {
      padding: 80px 0;
      text-align: left;
    }
  }

  .breadcrumbs {
    margin-bottom: 15px;
  }

  .hero-title {
    margin: 0;
    padding: 15px 0;
    line-height: 1;
    margin-bottom: 0;
    padding-bottom: 5px;
  }

  .hero-price {
    color: $color-accent1;
    margin: 0;
    padding: 0;
    font-size: 35px;

    @media (min-width: 600px) {
      font-size: 85px;
    }

    sup {
      position: relative;
      top: -20px;
      font-size: 50%;
      margin-left: 5px;
    }

    small {
      color: #fff;
    }
  }

  .restart-btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    padding: 3px;
    border-radius: 3px;

    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;

    @media (min-width: 600px) {
      width: auto;
    }

    .btn {
      width: 50%;
      padding: 15px 25px;
      margin-top: 35px;
      border-radius: 0;
      border: none;
      background: #fff;
      color: $dark;
      text-align: left;
      line-height: 1;
      vertical-align: center;

      font-size: 12px;

      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

      cursor: pointer;

      @media (min-width: 600px) {
        width: auto;
      }

      &.copy-btn {
        display: flex;
        align-items: center;
        background: $dark;
        color: #fff;
        text-align: left;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

        svg {
          margin-left: 10px;
          width: 15px;
        }
      }
    }
  }

  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($dark, 0.6);
      z-index: 1;
    }

    img {
      display: block;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}
</style>
